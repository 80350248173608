import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { VendorEmailTemplate, Page } from "@/types";

type VendorEmailTemplateState = {
  vendorEmailTemplatePage: Page<VendorEmailTemplate>;
};

const vendorEmailTemplate: Module<VendorEmailTemplateState, unknown> = {
  namespaced: true,
  state: () => ({
    vendorEmailTemplatePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getVendorEmailTemplate: (state) => (vendorEmailTemplateId: string) =>
      state.vendorEmailTemplatePage.docs.find(
        (c) => c._id === vendorEmailTemplateId
      ),
    vendorEmailTemplatePage: (state) => state.vendorEmailTemplatePage,
  },
  mutations: {
    ADD_VENDOR_EMAIL_TEMPLATE: (state, _vendorEmailTemplate) => {
      let idx = -1;
      state.vendorEmailTemplatePage.docs.map((r, i) => {
        if (r._id === _vendorEmailTemplate._id) idx = i;
      });
      if (idx === -1)
        state.vendorEmailTemplatePage.docs.push(_vendorEmailTemplate);
      else
        Vue.set(state.vendorEmailTemplatePage.docs, idx, _vendorEmailTemplate);
    },
    SET_VENDOR_EMAIL_TEMPLATE_PAGE: (state, list) => {
      state.vendorEmailTemplatePage = list;
    },
    REMOVE_VENDOR_EMAIL_TEMPLATE(state, vendorEmailTemplate) {
      let idx = -1;
      state.vendorEmailTemplatePage.docs.map((r, i) => {
        if (r._id === vendorEmailTemplate._id) idx = i;
      });
      if (idx > -1) state.vendorEmailTemplatePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchVendorEmailTemplate(context, params = "") {
      api
        .get(`/v1/vendor-email-template${params}`)
        .then((response) => {
          context.commit(
            "ADD_VENDOR_EMAIL_TEMPLATE",
            response.data.vendorEmailTemplate
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchVendorEmailTemplateList(context, params = "") {
      api
        .get(`/v1/vendor-email-template${params}`)
        .then((response) => {
          context.commit(
            "SET_VENDOR_EMAIL_TEMPLATE_PAGE",
            response.data.vendorEmailTemplatePage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createVendorEmailTemplate(context, payload) {
      return await api
        .post(`/v1/vendor-email-template`, payload)
        .then((response) => {
          context.commit(
            "ADD_VENDOR_EMAIL_TEMPLATE",
            response.data.vendorEmailTemplate
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Email Template created",
            },
            { root: true }
          );
          return response.data.vendorEmailTemplate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateVendorEmailTemplate(
      context,
      data: { id: string; vendorEmailTemplate: VendorEmailTemplate }
    ) {
      return await api
        .put(`/v1/vendor-email-template/${data.id}`, data.vendorEmailTemplate)
        .then((response) => {
          context.commit(
            "ADD_VENDOR_EMAIL_TEMPLATE",
            response.data.vendorEmailTemplate
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Email Template updated",
            },
            { root: true }
          );
          return response.data.vendorEmailTemplate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteVendorEmailTemplate(context, id) {
      api
        .delete(`/v1/vendor-email-template${id}`)
        .then((response) => {
          context.commit(
            "REMOVE_VENDOR_EMAIL_TEMPLATE",
            response.data.vendorEmailTemplate
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Email Template deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default vendorEmailTemplate;
