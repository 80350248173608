



















































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Role, VendorEmailTemplate } from "@/types";
import emailTemplateStoreModule from "@/store/modules/vendorEmailTemplate";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapGetters: emailTemplateGetters, mapActions: emailTemplateActions } =
  createNamespacedHelpers("EMAIL_TEMPLATE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "EmailTemplateList",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    deleteEmailTemplateDialog: false,
    options: {} as { page: number },
    settingDialog: false,
    sendOn: new Date(),
    numberOfDays: null as null | number,
    numberOfAppointments: null as null | number,
    template: undefined as undefined | VendorEmailTemplate,
    valid: false,
  }),
  computed: {
    ...emailTemplateGetters(["vendorEmailTemplatePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    hint(): string {
      return this.template?.conditions
        ? `Current: ${moment(this.template?.conditions.sendOn)
            .tz(tz)
            .format("DD/MM/YYY, H:mmA")}`
        : "";
    },
  },
  watch: {
    role: "fetchEmailTemplates",
    options: {
      handler() {
        this.fetchEmailTemplates();
      },
      deep: true,
    },
  },
  methods: {
    ...emailTemplateActions([
      "fetchVendorEmailTemplateList",
      "updateVendorEmailTemplate",
    ]),
    toggleDeleteEmailTemplate() {
      this.deleteEmailTemplateDialog = true;
    },
    fetchEmailTemplates() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchVendorEmailTemplateList(
          `?businessId=${bid}&page=${this.options.page || 1}`
        );
      }
    },
    updateStatus(id: string, status: string) {
      this.updateVendorEmailTemplate({
        id,
        vendorEmailTemplate: { status },
      }).then(() => this.fetchEmailTemplates());
    },
    validateSettings() {
      (
        this.$refs.settingForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      this.updateVendorEmailTemplate({
        id: this.template?._id,
        vendorEmailTemplate: {
          conditions: {
            numberOfDays: this.numberOfDays,
            numberOfAppointments: this.numberOfAppointments,
            sendOn: this.sendOn,
          },
        },
      }).then((tmp) => {
        if (tmp) {
          this.fetchEmailTemplates();
          this.settingDialog = false;
        }
      });
    },
    resetForm() {
      if (this.template?.conditions) {
        this.sendOn = this.template.conditions.sendOn;
        this.numberOfDays = this.template.conditions.numberOfDays;
        this.numberOfAppointments =
          this.template.conditions.numberOfAppointments;
      } else {
        this.sendOn = new Date();
        this.numberOfDays = null;
        this.numberOfAppointments = null;
      }
      (
        this.$refs.campaignForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMAIL_TEMPLATE_LIST")) {
      this.$store.registerModule(
        "EMAIL_TEMPLATE_LIST",
        emailTemplateStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMAIL_TEMPLATE_LIST");
  },
});
